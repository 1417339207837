import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideBar from "../components/sideBar"
import { layoutStyles } from '../style/layoutStyles';
import Tides from "../components/openingTimes"

export default ({ data }) => {
  const post = data.wpgraphql.marina
  var keywords = [];
  if (post.keywords.nodes != null)
  {
    post.keywords.nodes.forEach(node => { keywords.push(node.name);});
  }
  return (
    <Layout>
       <SEO title={post.title} keywords={keywords} />
        <div css={layoutStyles} id="content">
        <div className="grid">
            <div id="sectionPage" className="col-1-1">
              <div id="article" className="col-9-12">
                <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1>
                 <div dangerouslySetInnerHTML={{ __html: post.content }} />
                 <Tides />
              </div>

              <div id="aside" className="col-3-12">
                <SideBar FullWidth={true}  />
              </div>

          </div>
        </div>
        <div className="whitespacer"></div>
      </div>

    </Layout>
  )
}
export const query = graphql`
query GET_OPENINGTIMES($id: ID!) {
  wpgraphql {
    marina(id: $id) {
      title
      slug
      keywords {
        nodes {
          name
        }
      }
      content(format: RENDERED)
    }
    }
}
`